/** ************************************************************
                        Feedback API
************************************************************* */
/** Check if user can use email */
export const ApiFeedbackPost = () => ({
  method: 'POST',
  url: 'https://api.airtable.com/v0/appNW83WUEJCm5JV9/Feedback',
  data: {
    feedback: '',
  },
});
