import { ApiFeedbackPost } from '@//apiDocument/feedback/index.js';

export default {
  name: '',
  data() {
    return this.initial_state();
  },
  components: {},
  methods: {
    initial_state() {
      return {
        feedback: '',
        disabled: false,
      };
    },
    async submit_feedback() {
      if (this.feedback != '') {
        // Start loading animation
        this.$store.commit(this.$toastLoading);
        this.disabled = true;

        const api = ApiFeedbackPost();
        api.data = { fields: { feedback: this.feedback } };
        // Submit api
        const resp = await this.$callApi(api);
        console.log(resp);
        if (resp != null) {
          this.feedback = '';
          this.$bvModal.hide('feedback-model');

          // Set toast as succeeded
          this.$store.commit(this.$toastSet, {
            message: 'Thank you for your feedback!',
            error: false,
          });
        }
      }
    },
  },
  mounted() {},
};
